$gradebook-table__cell-border: $els-thin-keyline-width solid $els-color-n3;

$gradebook-table__cell-size: calc(3.6 * #{$els-space});
$gradebook-table__overall-cell-size: $els-space-4x;

$gradebook-table__student-cell-width: $space-14x;
$gradebook-table__horizontal-asm-type-cell-content-width: $space-11x;
$gradebook-table__vertical-asm-type-cell-content-height: $space-11x;
$gradebook-table__vertical-asm-type-cell-content-width: calc(#{$gradebook-table__vertical-asm-type-cell-content-height} - #{$gradebook-table__cell-size});

@mixin truncate-text {
  @extend .u-els-text-ellipsis;
}

@mixin vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

@mixin center-content {
  display: flex;
  align-items: center;
}

.c-cw-gradebook-table {
  border-top: $els-thick-keyline-width solid $els-color-n3;
  overflow: auto;

  .c-els-link:hover {
    text-decoration: underline;
    border-color: transparent;
  }

  &__cell {
    &--red {
      background: #efdedc !important;
    }

    &--green {
      background: #c7edc5 !important;
    }
  }

  .c-els-table {
    width: 100%;
    border-bottom: $gradebook-table__cell-border;

    &__cell {
      text-align: center;
      width: $gradebook-table__cell-size;
      min-width: $gradebook-table__cell-size;
      height: $gradebook-table__cell-size;
      padding: $els-space-1x1o4 $els-space-1o2 $els-space;
      border-right: $gradebook-table__cell-border;

      &--header {
        text-align: left;
        border-bottom: $gradebook-table__cell-border;
      }

      &.c-cw-gradebook-table__student-name-cell {
        width: $gradebook-table__student-cell-width;
        min-width: $gradebook-table__student-cell-width;
        text-align: left;

        @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
          width: $els-space-5x;
          min-width: $els-space-5x;
        }
      }

      &.c-cw-gradebook-table__student-name-header-cell,
      &.c-cw-gradebook-table__student-name-cell {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
      }

      &.c-cw-gradebook-table__vertical-overall-grade-cell {
        width: $gradebook-table__overall-cell-size;

        .c-els-table__cell-content {
          width: 100%;
          height: 100%;
          @include vertical-text;
          @include center-content;
        }
      }

      &.c-cw-gradebook-table__horizontal-assignment-type-cell {
        padding: $els-space-3o4 $els-space-1o2 $els-space;

        .c-cw-gradebook-table__horizontal-assignment-type-cell-content {
          @include truncate-text;
        }
      }

      &.c-cw-gradebook-table__vertical-assignment-type-cell {
        .c-els-table__cell-content {
          width: $gradebook-table__horizontal-asm-type-cell-content-width;
          height: $gradebook-table__vertical-asm-type-cell-content-height;
          @include vertical-text;
          @include center-content;
        }
      }

      &.c-cw-gradebook-table__vertical-assignment-name-cell {
        padding: $els-space;

        .c-els-table__cell-content {
          height: $gradebook-table__vertical-asm-type-cell-content-height;
          @include vertical-text;
          @include truncate-text;
        }
      }

      &.c-cw-gradebook-table__class-average-cell {
        padding: $els-space-1o4 $els-space-1o4 0;
        height: $gradebook-table__overall-cell-size;
        font-weight: bold;

        .c-els-table__cell-content {
          width: 100%;
          min-width: calc(8 * #{$els-space});
          height: 100%;
          flex-wrap: wrap;
          @include center-content;
          justify-content: center;
        }

        &:first-child {
          padding-left: $els-space-1o2;

          .c-els-table__cell-content {
            justify-content: left;
          }
        }
      }

      &--expanded {
        &.c-cw-gradebook-table__vertical-assignment-type-cell {
          .c-els-table__cell-content {
            width: $gradebook-table__vertical-asm-type-cell-content-width;
          }
        }
      }
    }
  }

  &__class-average-cell-icon {
    position: relative;
    top: -$els-space-1o4;
  }

  &__expand-icon {
    position: relative;
    top: 0.4rem;
    border: $els-thick-keyline-width solid $els-color-secondary;
    border-radius: 50%;
  }
}
