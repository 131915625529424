$search-box-width: calc(17.4 * #{$els-space-1x});

.c-ps-student-profile {
  &__header-below {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &-graduation-date {
      margin-left: auto;
    }

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
      flex-wrap: wrap;

      &-graduation-date {
        margin: $els-space;
        margin-bottom: 0;
        order: 4;
      }
    }
  }

  &__summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $els-space;

    .c-els-card {
      border: $els-thin-keyline-width solid $els-color-n3;
      background-color: $els-color-background;
      flex: 1;
    }

    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__menu-button {
    margin-left: auto;

    &-icon {
      display: none;

      @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
        display: block;
        position: sticky;
        top: 0;
        left: 92%;
        padding: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        background: white;
      }
    }
  }

  &__back-button {
    color: $els-color-n10;

    &:hover {
      background-color: $els-color-background;
      color: $els-color-n10;
    }
  }

  &__report-cards {
    display: grid;
    gap: $els-space;
    grid-template-areas:
      'access-and-review access-and-review access-and-review build-knowledge'
      'access-and-review access-and-review access-and-review apply-clinical-judgement';

    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'access-and-review access-and-review'
        'access-and-review access-and-review'
        'build-knowledge apply-clinical-judgement';
    }

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'access-and-review'
        'access-and-review'
        'build-knowledge'
        'apply-clinical-judgement';
    }

    &-badge {
      display: flex;
      border-radius: $els-space;
      align-items: center;
      justify-content: center;
      padding: $els-space-1o4 $els-space-3o4;
      width: fit-content;
      margin-bottom: $els-space-1o2;

      svg {
        margin-right: $els-space-1o2;
      }
    }

    .c-report-insight-card {
      &__subtitle {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &__access-and-review {
    grid-area: access-and-review;
    display: flex;
    min-height: calc(25 * #{$els-space});
    font-size: $els-fs-meta;
    border: $els-thin-keyline-width solid $els-color-n3;
    border-radius: $els-space-1o2;

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
      flex-wrap: wrap;
    }

    &-card {
      flex: 1 1 50%;
      padding: $els-space $els-space-1x1o2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;

      @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
        flex: 1 1 100%;
      }

      &:nth-child(odd) {
        border-right: $els-thin-keyline-width solid $els-color-n3;

        @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
          border-bottom: $els-thick-keyline-width solid $els-color-n3;
        }
      }

      &-pill {
        height: $els-space-1x1o4;
        margin: $els-space-1o2 0;
        line-height: 1;
        font-size: $els-space-3o4;
      }

      &-title {
        &--clickable {
          color: $els-color-secondary;
          text-decoration: underline;
          text-underline-offset: $els-space-1o2;
          cursor: pointer;
        }
      }

      &-chart {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin: $els-space auto;
        width: 100%;
        align-items: center;
      }

      &-circular-progress-chart {
        max-width: calc(17 * #{$els-space});
        height: calc(10 * #{$els-space});
        width: 100%;
        text-align: center;
      }

      &-score-scale-chart {
        max-width: calc(20 * #{$els-space});
        height: calc(12 * #{$els-space});
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3%;

        @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
          margin-top: 0;
        }

        &-label {
          width: 95%;
          display: flex;
          justify-content: space-around;
        }
      }

      &-metric {
        @extend .u-els-font-size-base;
        flex: 1 1 auto;
        justify-content: space-between;
        border-bottom: $els-thin-keyline-width solid $els-color-n3;
        margin: $els-space-1o2 0;
        display: flex;
        padding-bottom: $els-space-1o4;

        &-title {
          flex: 0 0 60%;
          display: flex;
          @extend .u-els-text-ellipsis;

          &-text {
            @extend .u-els-text-ellipsis;
          }
        }

        &-value {
          @extend .u-custom-color-dark;
          text-align: right;
          flex: 0 0 40%;
          font-weight: bold;
        }

        &:last-child {
          border: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        &s {
          display: flex;
        }
      }
    }
  }

  .c-ps-report-card__pill-title {
    color: $els-color-secondary;
  }

  &__tabs {
    &--hidden {
      .c-els-tab-group {
        display: none;
      }
    }

    > .c-els-tab-group {
      max-width: calc(87.5 * #{$els-space});
      margin: auto;
      padding: $els-space-1o4 $els-space-1x1o2 0;
      background-color: $els-color-n0;

      a.c-els-tab-group__text:hover {
        color: $els-color-n8;
      }
    }

    .o-els-container {
      border-top: $els-thin-keyline-width solid $els-color-n10;
    }
  }

  .c-els-tab-group {
    &__item,
    &__item--active {
      margin: 0;
    }

    &__text,
    &__text:focus {
      color: $els-color-n8;
      padding: $els-space-1o4 $els-space;
    }
  }

  .o-els-container {
    background-color: white;
    margin: 0;
  }

  &__content-tab {
    .o-els-container {
      margin-top: 0;
    }
  }

  &__nested-tabs {
    .c-els-tab-group {
      width: fit-content;

      &__item {
        cursor: pointer;
        padding-left: $els-space;
        padding-right: $els-space;
        background-color: white;
        border: $els-thick-keyline-width solid $els-color-secondary;
        &:not(:first-child) {
          border-left: none;
        }
        &:hover {
          background-color: $els-color-primary;
          color: white;
        }
        &--active {
          background-color: $els-color-secondary;
          color: white;
          &:hover {
            background-color: $els-color-secondary;
          }
          &:focus {
            color: white;
          }
        }
        &--disabled {
          color: $els-color-n6;
          background-color: white;
          border-color: transparent;
          pointer-events: none;
        }
      }
    }
  }

  &__legend {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: $els-space-1x1o4;
      gap: $els-space;

      .c-legend-label-box {
        background-color: $els-color-background;
        padding: $els-space-1o2 $els-space;
        height: fit-content;
      }
    }
  }

  &__hesi-exam-performance {
    .c-els-table__cell,
    .c-table-card {
      background-color: $els-color-background;
    }
  }

  &__hesi-monthly-trend {
    position: relative;

    &-line {
      background-color: $els-color-background;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
      padding: $els-space-4x $els-space-2x $els-space;
      border: $els-thin-keyline-width solid $els-color-n3;
    }

    &-side-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: $els-color-background;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
      width: calc(125 * #{$els-space-1o8});
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  &__notes-export {
    display: flex;
    justify-content: flex-end;
  }

  &__notes-items {
    height: 100%;
    overflow-y: auto;
  }

  &__notes-items::-webkit-scrollbar {
    width: 6px;
  }

  &__notes-items::-webkit-scrollbar-thumb {
    background-color: $els-color-n6;
    border-radius: 10px;
  }

  &__notes-items::-webkit-scrollbar-track {
    background: $els-color-background;
  }

  &__notes-feedback {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }

  &__add-student-notes {
    margin-right: $els-space;
    margin-bottom: $els-space;
    padding: $els-space-1o2 $els-space-3o4;
    z-index: 1;
  }

  &__topics-mastery {
    &-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: $els-space;

      .c-list-box {
        &__trigger-arrow {
          color: $els-color-secondary;
        }

        &__options {
          @extend .u-els-font-size-meta;
          width: 100%;
        }
      }

      .c-select-menu {
        &__options-search-input {
          margin-bottom: 0;
        }
      }
    }

    &-filter {
      margin-bottom: $els-space;
      font-size: $els-fs-h4;

      .c-list-box {
        width: fit-content;

        &__trigger {
          color: $els-color-secondary;
          border-color: $els-color-secondary;
          padding-bottom: 0;

          &:hover {
            color: $els-color-primary;
            border-color: $els-color-primary;
          }
        }

        &__options {
          @extend .u-els-font-size-meta;
          width: 100%;
        }
      }
    }

    &-sort {
      display: flex;
      align-items: center;
      margin-right: auto;
      min-width: 25%;

      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        width: 100%;
      }

      &-label {
        flex: 0 0 auto;
        margin-right: $els-space-1o2;
      }

      .c-els-field {
        flex: 1 1 auto;
      }

      .c-list-box {
        background-color: white;

        &__trigger {
          display: flex;
          border: $els-thin-keyline-width solid $els-color-n3;
          border-bottom: $els-thick-keyline-width solid $els-color-n8;
          padding: $els-space-1o2 $els-space-1o2 $els-space-1o2 $els-space;
        }

        &__options {
          width: 100%;
        }
      }
    }

    &-search {
      width: 25%;

      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        width: 100%;
        margin-right: 0;
      }
    }

    &-content {
      margin-top: $els-space-1x1o2;
    }
  }

  &__hesi-exam-categories {
    &-table {
      .c-els-table {
        background-color: $els-color-background;
      }
    }
    //Override the chart background color
    .c-had-cohort-detail-body__tab-content-wrapper {
      background-color: white !important;
    }
  }

  .c-ps-student-search {
    margin-top: 0;

    &__box {
      width: $search-box-width;
    }

    .c-search-menu {
      padding-right: 0;
      width: $search-box-width;

      &__options {
        width: $search-box-width;
        font-size: $els-fs-body;

        &-search-input {
          width: $search-box-width;
        }

        .c-select-menu {
          &__options-search-input {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
