$student-name-width: calc(12.5 * $els-space);
$overall-score-width: calc(8.5 * $els-space);
$date-taken-width: calc(8.2 * $els-space);
$min-width-score: calc(9 * $els-space);

$zindex-overtable: calc(map-get($zindex, table-header) + 1);

.c-student-performance-tab {
  position: relative;

  &__mask-bar {
    position: absolute;
    height: $els-space;
    width: 100%;
    background-color: white;
    bottom: calc($els-space * 4.6875);
    @supports (-webkit-appearance: none) {
      z-index: $zindex-overtable;
    }
  }

  &__header {
    margin-bottom: $els-space-2x;
    &-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--search-bar {
        width: 20%;
      }
    }
  }

  &__table {
    position: relative;
    &-date-taken {
      position: relative;
      pointer-events: none;
    }
    &-scroll-bar {
      overflow-x: auto;
      z-index: $zindex-overtable;
      position: absolute;
      bottom: -$els-thin-keyline-width;
      transform: translateX(calc(100%));
      right: -$els-thin-keyline-width;
      width: $els-space;
      background-color: $els-color-n2;
      &__line {
        height: $els-thin-keyline-width;
        pointer-events: none;
        width: $els-space;
      }
      &--hover {
        transition: none;

        &:focus,
        &:hover {
          outline: none !important;
          border-bottom: none !important;
          color: $els-color-secondary !important;
          padding-bottom: calc($els-thin-keyline-width * 1.5);
          color: unset;
        }
      }
    }
    &-subject-area-score,
    &-composite-score {
      &--header {
        z-index: map-get($zindex, table-header);
        pointer-events: none;
        color: $els-color-text;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: $els-space-3o4 0;
        background-color: $els-color-n2;
        border-right: calc($els-thin-keyline-width/2) solid $els-color-n5;
        font-weight: bold;
        -webkit-font-smoothing: subpixel-antialiased;
      }
      &-header {
        position: relative;
        height: calc($els-space * 6.5);
      }
    }

    .c-els-table__cell--right {
      .c-els-table__sortable-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
    }

    th.c-els-table__cell {
      position: relative;
      background-color: white;
      padding-bottom: $els-space;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        z-index: $zindex-overtable !important;
      }
    }

    .c-els-table--shadow-header-bottom {
      box-shadow: none;
    }

    .c-els-table__sortable-button:has(.c-student-performance-tab__table-scroll-bar--hover) {
      .c-els-table__sort-icon {
        color: $els-color-secondary !important;
      }
    }

    .c-els-table__cell {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        background-color: white;
        position: sticky;
      }

      &:first-child {
        min-width: $student-name-width;
      }
      &:nth-child(2) {
        min-width: $overall-score-width;
        left: $student-name-width;
      }
      &:nth-child(3) {
        min-width: $date-taken-width;
        left: calc($overall-score-width + $student-name-width);
        border-right: $els-thin-keyline-width solid $els-color-n5;
        transition: none;
      }
      &:nth-child(n + 4) {
        width: $min-width-score;
        min-width: $min-width-score;
      }

      &:last-child {
        border-right: $els-thin-keyline-width solid $els-color-n5;
      }

      &:has(.c-student-performance-tab__table-composite-score--last) {
        border-right: $els-thin-keyline-width solid $els-color-n5;
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: map-get($zindex, table-header);
      height: 0;
      width: 100%;
      bottom: 0;
      border-top: $els-thin-keyline-width solid $els-color-n2;
      background-color: white;
    }
  }
}
